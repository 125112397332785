import {EthNetwork} from '@/utils/shared';
import {NEXT_PUBLIC_ETH_NETWORK, NEXT_PUBLIC_ETH_PROVIDER} from '@/utils/env';

const environment = process.env.NEXT_PUBLIC_STAGING
  ? 'staging'
  : process.env.NODE_ENV;

export const isValidEthNetwork = (network?: string): network is EthNetwork => {
  switch (network) {
    case 'hardhat':
    case 'mainnet':
    case 'rinkeby':
    case 'ropsten':
    case 'goerli':
    case 'sepolia':
    case 'localhost':
      return true;
    default:
      return false;
  }
};

export const getNetworkByEnvironment = (env: string) => {
  // NB: see your `.env.*` files (e.g. `.env.development`)
  const network = NEXT_PUBLIC_ETH_NETWORK;

  if (isValidEthNetwork(network)) {
    return network;
  }

  // TODO: support other networks!
  switch (env) {
    case 'development':
      return 'sepolia';
    case 'staging':
      return 'sepolia';
    case 'production':
      return 'mainnet';
    default:
      return 'sepolia';
  }
};

export const getProviderByEnvironment = (env: string) => {
  // This should be set for production in .env and staging in Vercel Environment Variables
  const network = NEXT_PUBLIC_ETH_PROVIDER;

  if (network && network.length > 0) {
    return network;
  }

  switch (env) {
    case 'development':
      // Comment out NEXT_PUBLIC_ETH_PROVIDER in .env.development
      // in order to use local network
      return 'http://localhost:8545';
    default:
      return 'https://eth-sepolia.g.alchemy.com/v2/PxCOtyRXaFliHTwZXAoa7NDdMNJnoawn';
  }
};

export const ETH_NETWORK = getNetworkByEnvironment(environment);
export const ETH_PROVIDER = getProviderByEnvironment(environment);
