import {AbiItem} from 'web3-utils';
import {ETH_NETWORK} from '@/utils/contracts/network';

const getProducerPassContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_PRODUCER_PASS_CONTRACT_ADDRESS;

  if (address && address.length > 0) {
    return address;
  }

  // NB: the code below should not be used until we can streamline deployments + seeding data
  // (At the moment there are a few manual steps required to set up the platform)
  switch (network) {
    case 'goerli':
      const data = require('../../../generated/addresses/WhiteRabbitProducerPass.goerli.json');

      return data.address;
    case 'ropsten':
    case 'hardhat':
    case 'localhost':
      throw new Error(`Address not available for network: ${network}`);
    default:
      throw new Error(`Address not available for network: ${network}`);
  }
};

const getProducerPassStakingContractAddress = (network: string): string => {
  const address =
    process.env.NEXT_PUBLIC_PRODUCER_PASS_STAKING_CONTRACT_ADDRESS;

  if (address && address.length > 0) {
    return address;
  }

  // NB: the code below should not be used until we can streamline deployments + seeding data
  // (At the moment there are a few manual steps required to set up the platform)
  switch (network) {
    case 'goerli':
      const data = require('../../../generated/addresses/ProducerPassStaking.goerli.json');

      return data.address;
    case 'ropsten':
    case 'hardhat':
    case 'localhost':
      throw new Error(`Address not available for network: ${network}`);
    default:
      throw new Error(`Address not available for network: ${network}`);
  }
};

const getDistributionContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_DISTRIBUTION_CONTRACT_ADDRESS;
  if (address && address.length > 0) {
    return address;
  }

  // NB: the code below should not be used until we can streamline deployments + seeding data
  // (At the moment there are a few manual steps required to set up the platform)
  switch (network) {
    case 'goerli':
      const data = require('../../../generated/addresses/WhiteRabbitProducerPassDistribution.goerli.json');

      return data.address;
    case 'ropsten':
    case 'hardhat':
    case 'localhost':
      throw new Error(`Address not available for network: ${network}`);
    default:
      throw new Error(`Address not available for network: ${network}`);
  }
};

const getProducerPassStakingAbi = () => {
  const metadata = require('../../../generated/contracts/ProducerPassStaking.json');

  return metadata.abi as Array<AbiItem>;
};

const getDistributionContractAbi = () => {
  const metadata = require('../../../generated/contracts/WhiteRabbitProducerPassDistribution.json');

  return metadata.abi as Array<AbiItem>;
};

const getProducerPassContractAbi = () => {
  const metadata = require('../../../generated/contracts/WhiteRabbitProducerPass.json');

  return metadata.abi as Array<AbiItem>;
};

export const PRODUCER_PASS_CONTRACT_ADDRESS =
  getProducerPassContractAddress(ETH_NETWORK);
export const DISTRIBUTION_CONTRACT_ADDRESS =
  getDistributionContractAddress(ETH_NETWORK);
export const PRODUCER_PASS_STAKING_CONTRACT_ADDRESS =
  getProducerPassStakingContractAddress(ETH_NETWORK);

export const PRODUCER_PASS_CONTRACT_ABI = getProducerPassContractAbi();
export const PRODUCER_PASS_STAKING_CONTRACT_ABI = getProducerPassStakingAbi();
export const DISTRIBUTION_CONTRACT_ABI = getDistributionContractAbi();
