import Web3 from 'web3';
import axios from 'axios';

export type EthNetwork =
  | 'hardhat'
  | 'localhost'
  | 'private'
  | 'rinkeby'
  | 'ropsten'
  | 'mainnet'
  | 'goerli'
  | 'sepolia'
  | 'kovan'
  | 'main';

export const SUPPORTED_CHAIN_IDS = [1, 3, 4, 5, 42, 1337];

export const ChainIDToNetwork: {[key: number]: EthNetwork} = {
  1: 'mainnet',
  3: 'ropsten',
  4: 'rinkeby',
  5: 'goerli',
  42: 'kovan',
  11155111: 'sepolia',
};

export const WALLET_CONNECTED_PROVIDER = `shibuya.provider`;

export const ethereum =
  typeof window !== 'undefined' ? (window as any)?.ethereum : undefined;

export const noop = () => {};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const times = <T>(n: number, fn: (index: number) => T) =>
  Array.from({length: n}).map((_, index) => fn(index));

// Generates range from `start` to `finish` (not including `finish`)
// e.g. `range(5, 10)` => [5, 6, 7, 8, 9]
export const range = (start: number, finish: number) => {
  const list = [];

  for (let i = start; i < finish; i++) {
    list.push(i);
  }

  return list;
};

export const formatLongText = (text: string, sub = 7) => {
  if (!text) {
    return '';
  }

  if (text.length < sub * 2) {
    return text;
  }

  return `${text.substring(0, sub)}...${text.substring(text.length - sub)}`;
};

export const formatTwitterHandle = (handle: string) => {
  if (!handle) {
    return handle;
  } else if (handle.indexOf('twitter.com/') !== -1) {
    const sections = handle.split('twitter.com/');
    const h = sections[sections.length - 1];

    return h.startsWith('@') ? h : `@${h}`;
  } else {
    return handle.startsWith('@') ? handle : `@${handle}`;
  }
};

// TODO: we should not have to do this, but for some reason
// the WR token balance can get absurdly high at the moment
// NB: maybe it's because it's using a different unit?
export const WR_TOKEN_OFFSET = Math.pow(10, 18);

export const formatWrTokenAmount = (amount: number) => {
  return amount / WR_TOKEN_OFFSET;
};

export const formatLargeNumberToString = (num: number, power: number) => {
  const str = num.toString();
  const [_, decimals = ''] = str.split('.');
  const d = decimals.length;
  const zeroes = '0'.repeat(power - d);

  return (num * Math.pow(10, d)).toString().concat(zeroes);
};

// Gets the next lowest power of ten below `balance`
// (Used for animating the `CountUp` component value)
export const getBalanceCountUpStartValue = (balance: number): number => {
  if (balance < 10) {
    return 0;
  }

  const zeroes = String(Math.round(balance)).length - 1;

  return Number(`1${'0'.repeat(zeroes)}`);
};

export type EtherscanTestNet = 'rinkeby' | 'ropsten' | 'goerli';

export const isValidEtherscanTestNetwork = (
  network: string
): network is EtherscanTestNet => {
  switch (network) {
    case 'rinkeby':
    case 'ropsten':
    case 'goerli':
    case 'private':
      return true;
    default:
      return false;
  }
};

export const isValidEtherscanNetwork = (
  network: string
): network is EthNetwork => {
  switch (network) {
    case 'mainnet':
    case 'main':
      return true;
    default:
      return isValidEtherscanTestNetwork(network);
  }
};

export const getEtherscanAddressUrl = (
  address: string,
  {network}: {network?: EthNetwork | EtherscanTestNet | null}
) => {
  if (network && isValidEtherscanTestNetwork(network)) {
    return `https://${network}.etherscan.io/address/${address}`;
  }

  return `https://etherscan.io/address/${address}`;
};

export const getEtherscanTransactionUrl = (
  txn: string,
  {network}: {network?: EthNetwork | EtherscanTestNet | null}
) => {
  if (network && isValidEtherscanTestNetwork(network)) {
    return `https://${network}.etherscan.io/tx/${txn}`;
  }

  return `https://etherscan.io/tx/${txn}`;
};

export const logEtherscanTransactionUrl = (
  txn: string,
  {network, debug = true}: {network?: EthNetwork; debug?: boolean}
) => {
  if (!debug || network === 'localhost' || network === 'hardhat') {
    return;
  }

  console.debug(
    'View transaction on etherscan:',
    getEtherscanTransactionUrl(txn, {network})
  );
};

export const calculateEthHexFromWei = ({
  wei,
  multiplier,
}: {
  wei: string;
  multiplier: number;
}): string => {
  const bnEthAmount = Web3.utils.toBN(wei); // BN{ 50000000000000000 }
  const bnMultiplier = Web3.utils.toBN(multiplier.toString()); // BN{ 5 }
  const bnAmount = bnEthAmount.mul(bnMultiplier); // BN{ 250000000000000000 }
  const bnAmountNumber = +bnAmount.toString(); // 250000000000000000
  const ethAmountHex = Web3.utils.numberToHex(bnAmountNumber); // 0x3782dace9d90000

  return ethAmountHex;
};

export const calculateEthHexFromAmountMultiplier = ({
  ethAmount,
  ethAmountMultiplier,
}: {
  ethAmount: number;
  ethAmountMultiplier: number;
}): string => {
  const wei = Web3.utils.toWei(ethAmount.toString()); // 50000000000000000

  return calculateEthHexFromWei({wei, multiplier: ethAmountMultiplier});
};

export const fetchEnsByAddress = async (address: string) => {
  try {
    const response = await fetch(`/api/ens?address=${address}`);
    const {data: result} = await response.json();
    return result;
  } catch (err: any) {
    console.error('Failed to fetch ENS:', err);
    return null;
  }
};

export const capitalize = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
